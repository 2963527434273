import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

export default function BredcrumbList(props) {
  const { location } = props
  // GraphQLの結果を格納する
  const { allSitePage } = useData()
  // サイトのパスリストを生成する
  const pathList = allSitePage.nodes.map(({ path }) => encodeURI(path))

  // 階層構造を生成する.
  const breadCrumbInfo = location.pathname
    .split("/")
    .filter(pathName => pathName !== "")
    .reduce(
      (pre, pathName) => {
        const parentPath = pre[pre.length - 1].path
        const currentPath = `${parentPath}${pathName}/`
        // currentPathがパスリスト中に存在するか検証する
        
        const pathListIndex = pathList.indexOf(currentPath)
        const isExistPage = pathListIndex !== -1

        // パンくずリストの階層名として表示するラベルを設定する
        const pageTitle =
          pathListIndex !== -1
            ? allSitePage.nodes[pathListIndex].context.pageTitle
            : pathName

        pre.push(convertToPageInfo(currentPath, pageTitle, isExistPage))
        return pre
      },
      [convertToPageInfo("/", "HOME", true)]
    )

  const breadCrumbList = breadCrumbInfo
    // 存在しないページは、パンくずリストから除外する
    .filter(({ isExistPage }) => isExistPage)

  return (
    <div className="breadClumb">
      <ul>
        {breadCrumbList.map((page,index) => (
          <li key={index}>
            <Link to={page.path}>
              {page.pageTitle}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

function convertToPageInfo(path, pageTitle, isExistPage) {
  return { path: path, pageTitle: pageTitle, isExistPage: isExistPage }
}

function useData() {
  return useStaticQuery(graphql`
    query {
      allSitePage {
        nodes {
          path
          context {
            pageTitle
          }
        }
      }
    }
  `)
}

