import React from 'react';
import { graphql } from 'gatsby';
import Seo from "../components/seo"
import BreadcrumbList from '../components/breadCrumbList.js'

const WpPublicationTemplate = ({ data: { allWpBook, wpPage }, location }) => {
  const { title, content, orderLinks } = wpPage;
  const { pricepdf, orderpdf, orderexcel } = orderLinks;

  const booksHTML = allWpBook.nodes.map((node, index) => {
    return (
      <tr key={index}>
        <th>{node.title}</th>
        <td><a href={node.uri} className="btn keyColor00b">詳細・注文</a></td>
      </tr>
    )
  })

  return (
    <>
      <Seo title={title} />
      <div id="pageTitle">
        <h1>{title}</h1>
      </div>
      <div className="breadClumb">
        <ul>
          <BreadcrumbList location={location}></BreadcrumbList>
        </ul>
      </div>
      <div id="main">
        <div className="contents">
          <section className="contents_block">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div className="inner">
              <ul className="list_style1">
                <li><a href={orderpdf} target="_blank" rel="noopener noreferrer">出版物注文書(PDF)</a></li>
                <li><a href={orderexcel} target="_blank" rel="noopener noreferrer">出版物注文書(Excel)</a></li>
                <li><a href={pricepdf} target="_blank" rel="noopener noreferrer">価格表(PDF)</a></li>
              </ul>
            </div>
          </section>
          <section className="contents_block">
            <h2 className="header01">出版図書一覧</h2>
            <div className="inner">
              <table className="bookTable">
                <tbody>
                  {booksHTML}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query ($id: String!){
    allWpBook {
      nodes {
        title
        uri
      }
    }
    wpPage(id: { eq: $id }) {
      title
      content
      orderLinks {
        pricepdf
        orderpdf
        orderexcel
      }
    }
  }
`;

export default WpPublicationTemplate;